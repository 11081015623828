
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StackedBarChartFilterable from '@/components/reports-v2/components/filterables/StackedBarChartFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { DimensionMap, PermissionsGroup } from '@/store/models.def';
import { GobiColor } from '@/helpers/color';
import gdbx from '@/store/modules/gdbx';
import * as echarts from 'echarts';
import { TooltipFormatter } from '../../elements/charts/helpers/tooltipFormatter';

@Component({
	components: {
		StackedBarChartFilterable,
	},
})
export default class QuickRatioBarChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateAsOf'];

	public xAxisData = [
		'CREDITOR & \n OTHER CURRENT LAIBILITY',
		'BANK & CASH',
		'DEBTORS & \n STOCK BALANCE',
	];
	public barType: string[] = ['float', 'static', 'static'];
	// public dataColor: string[] = [GobiColor.TEAL, GobiColor.PINK, GobiColor.BLUE];
	public xAxisValue: number[] = [];
	public series: echarts.ECharts[] | any = [];

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get formatter() {
		return TooltipFormatter.QuickRatio;
	}

	public get expensiveHook() {
		const { selectedAsOfDate } = this;
		return JSON.stringify([selectedAsOfDate]);
	}

	public async expensiveCalc() {
		const ref = FilteredDatabase.ref('transactions').dateAsOf(
			this.selectedAsOfDate,
		);

		// Current Asset

		const currentAssetMap: DimensionMap[] = [
			{
				filterType: 'string',
				filterKey: 'accType',
				value: ['CA'],
				text: 'CURRENT ASSETS',
			},
		];

		// Current Liability

		const currentLiabilityMap: DimensionMap[] = [
			{
				filterType: 'string',
				filterKey: 'accType',
				value: ['CL'],
				text: 'CURRENT LIABILITIES',
			},
		];

		const currentLiabilitySpecialAccTypeList: string[] = ['BA', 'DP', 'CR'];
		const currentLiabilitySpecialAccTypeNameList: string[] = [
			'BANK LIABILITIES',
			'\'DEBTOR\'s DEPOSIT\'',
			'CREDITORS',
		];

		const currentLiabilitySpecialAccTypeMap: DimensionMap[] =
			currentLiabilitySpecialAccTypeList.map((code, index) => ({
				filterType: 'string',
				filterKey: 'specialAccType',
				value: [code],
				text: currentLiabilitySpecialAccTypeNameList[index],
			}));

		// Debtors

		const debtorMap: DimensionMap[] = [
			{
				filterType: 'string',
				filterKey: 'specialAccType',
				value: ['DR'],
				text: 'DEBTORS',
			},
		];

		// Bank

		const bankMap: DimensionMap[] = [
			{
				filterType: 'string',
				filterKey: 'specialAccType',
				value: ['BA'],
				text: 'BANK',
			},
		];

		// Cash

		const cashMap: DimensionMap[] = [
			{
				filterType: 'string',
				filterKey: 'specialAccType',
				value: ['CH'],
				text: 'CASH',
			},
		];

		// Creditor

		const creditorMap: DimensionMap[] = [
			{
				filterType: 'string',
				filterKey: 'specialAccType',
				value: ['CR'],
				text: 'CREDITOR',
			},
		];

		let pa = 0.14;
		let pb = 0;

		const currentAssetsDD = await this._loadDimension(
			ref,
			currentAssetMap,
			pa,
			pb,
		);

		pb += pa;
		pa = 0.14;

		const currentLiabilityDD = await this._loadDimension(
			ref,
			currentLiabilityMap,
			pa,
			pb,
		);

		pb += pa;
		pa = 0.14;

		const debtorDatas = await this._loadDimension(
			ref,
			currentAssetsDD,
			pa,
			pb,
			(reff, paa, pbb) =>
				this._loadDimension(reff, debtorMap, paa, pbb, 'amount'),
		);

		pb += pa;
		pa = 0.14;

		const bankDatas = await this._loadDimension(
			ref,
			currentAssetsDD,
			pa,
			pb,
			(reff, paa, pbb) =>
				this._loadDimension(reff, bankMap, paa, pbb, 'amount'),
		);

		pb += pa;
		pa = 0.14;

		const cashDatas = await this._loadDimension(
			ref,
			currentAssetsDD,
			pa,
			pb,
			(reff, paa, pbb) =>
				this._loadDimension(reff, cashMap, paa, pbb, 'amount'),
		);

		pb += pa;
		pa = 0.14;

		const creditorDatas = await this._loadDimension(
			ref,
			currentLiabilityDD,
			pa,
			pb,
			(reff, paa, pbb) =>
				this._loadDimension(reff, creditorMap, paa, pbb, 'amount'),
		);

		pb += pa;
		pa = 0.14;

		const groupedCurrentLiabilitiesDD = await this._loadDimension(
			ref,
			currentLiabilityDD,
			pa,
			pb,
			(reff, paa, pbb) =>
				this._loadDimension(
					reff,
					currentLiabilitySpecialAccTypeMap,
					paa,
					pbb,
					'amount',
				),
		);

		this.generateLoadingText(1);

		const otherCurrentLiabilities =
			currentLiabilityDD[0].sum -
			groupedCurrentLiabilitiesDD
				.map((dd) => dd.sum)
				.reduce((a, b) => a + b, 0);

		const totalStockBalance = await FilteredDatabase.getStockMovement([
			0,
			this.selectedAsOfDate,
		]);
		const totalDebtor = debtorDatas.reduce((a, b) => a + b.sum, 0);
		const totalBank = bankDatas.reduce((a, b) => a + b.sum, 0);
		const totalCash = cashDatas.reduce((a, b) => a + b.sum, 0);
		const totalCreditor = creditorDatas.reduce((a, b) => a + b.sum, 0) * -1;

		const invisible = {
			name: '',
			type: 'bar',
			stack: 'A',
			tooltip: {
				show: false,
			},
			itemStyle: {
				borderColor: 'rgba(0,0,0,0)',
				color: 'rgba(0,0,0,0)',
			},
			emphasis: {
				itemStyle: {
					borderColor: 'rgba(0,0,0,0)',
					color: 'rgba(0,0,0,0)',
				},
			},
			data: [0, 0, totalBank + totalCash],
		};

		const debtors = {
			name: 'Debtor',
			type: 'bar',
			stack: 'A',
			itemStyle: {
				color: GobiColor.COLOR1,
			},
			data: [0, 0, totalDebtor],
		};

		const stockBalance = {
			name: 'Stock Balance',
			type: 'bar',
			stack: 'A',
			itemStyle: {
				color: GobiColor.COLOR2,
			},
			data: [0, 0, totalStockBalance],
		};

		const bank = {
			name: 'Bank',
			type: 'bar',
			stack: 'A',
			itemStyle: {
				color: GobiColor.COLOR3,
			},
			data: [0, totalBank, 0],
		};

		const cash = {
			name: 'Cash',
			type: 'bar',
			stack: 'A',
			itemStyle: {
				color: GobiColor.COLOR4,
			},
			data: [0, totalCash, 0],
		};

		const creditor = {
			name: 'Creditor',
			type: 'bar',
			stack: 'A',
			itemStyle: {
				color: GobiColor.COLOR5,
			},
			data: [totalCreditor, 0, 0],
		};

		const otherCurrentLiability = {
			name: 'Other Current Liability',
			type: 'bar',
			stack: 'A',
			itemStyle: {
				color: GobiColor.COLOR6,
			},
			data: [otherCurrentLiabilities * -1, 0, 0],
		};

		this.series = [
			invisible,
			creditor,
			otherCurrentLiability,
			bank,
			cash,
			debtors,
			stockBalance,
		];

		this.saveHistory('xAxisData', 'series');
	}
}
