
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StackedBarChartFilterable from '@/components/reports-v2/components/filterables/StackedBarChartFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {
	DimensionData,
	DimensionMap,
	PermissionsGroup,
} from '@/store/models.def';
import { GobiColor } from '@/helpers/color';
import gdbx from '@/store/modules/gdbx';
import * as echarts from 'echarts';
import { TooltipFormatter } from '../../elements/charts/helpers/tooltipFormatter';

@Component({
	components: {
		StackedBarChartFilterable,
	},
})
export default class AccountBalanceBarChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateAsOf'];

	public xAxisData = ['ASSET', 'LIABILITY', 'EQUITY'];
	public barType: string[] = ['static', 'float', 'static'];
	public dataColor: string[] = [GobiColor.COLOR1, GobiColor.COLOR3, GobiColor.COLOR4];
	public xAxisValue: number[] = [];
	public series: echarts.ECharts[] | any = [];

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get formatter() {
		return TooltipFormatter.AccountBalance;
	}

	// public get monthValues() {
	// 	const [d0, d1] = this.selectedDateRange.map((d) => moment(d));
	// 	const period = d1.diff(d0, 'month') + 1;
	// 	const result: Moment[] = [];
	// 	for (let i = 0; i < period; i++) {
	// 		result.push(d0.clone());
	// 		d0.add(1, 'month');
	// 	}
	// 	return result;
	// }

	public get expensiveHook() {
		const { selectedAsOfDate } = this;
		return JSON.stringify([selectedAsOfDate]);
	}

	public async expensiveCalc() {
		const ref = FilteredDatabase.ref('transactions').dateAsOf(
			this.selectedAsOfDate,
		);

		// Asset

		const assetTypeList: string[] = ['CA', 'FA', 'OA', 'IV'];
		const assetTypeNameList: string[] = [
			'CURRENT ASSETS',
			'NON-CURRENT ASSETS',
			'OTHER ASSETS',
			'INVESTMENT',
		];

		const assetMap: DimensionMap[] = assetTypeList.map((code, index) => ({
			filterType: 'string',
			filterKey: 'accType',
			value: [code],
			text: assetTypeNameList[index],
		}));

		// Liability

		const liabilityTypeList: string[] = ['CL', 'OL', 'LT'];
		const liabilityTypeNameList: string[] = [
			'CURRENT LIABILITIES',
			'OTHER LIABILITIES',
			'NON-CURRENT LIABILITIES',
		];

		const liabilityMap: DimensionMap[] = liabilityTypeList.map(
			(code, index) => ({
				filterType: 'string',
				filterKey: 'accType',
				value: [code],
				text: liabilityTypeNameList[index],
			}),
		);

		// Equity

		const equityTypeList: string[] = [
			'RE',
			'EP',
			'SL',
			'SA',
			'OI',
			'EO',
			'CO',
			'TX',
			'CP',
			'RV',
			'AP',
		];
		const equityTypeNameList: string[] = [
			'RETAINED EARNING',
			'EXPENSES',
			'SALES',
			'SALES ADJUSTMENT',
			'OTHER INCOME',
			'',
			'COST OF GOODS SOLD',
			'TAXATION',
			'EQUITY',
			'RESERVE',
			'',
		];

		const equityMap: DimensionMap[] = equityTypeList.map((code, index) => ({
			filterType: 'string',
			filterKey: 'accType',
			value: [code],
			text: equityTypeNameList[index],
		}));

		let pa = 0.3;
		let pb = 0;

		const assetDatas: DimensionData[] = await this._loadDimension(
			ref,
			assetMap,
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.3;

		const liabilityDatas: DimensionData[] = await this._loadDimension(
			ref,
			liabilityMap,
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.3;

		const equityDatas: DimensionData[] = await this._loadDimension(
			ref,
			equityMap,
			pa,
			pb,
			'amount',
		);

		this.generateLoadingText(1);

		const totalAsset = assetDatas.reduce((a, b) => a + b.sum, 0);
		const totalLiability = liabilityDatas.reduce((a, b) => a + b.sum, 0) * -1;
		const totalEquity = equityDatas.reduce((a, b) => a + b.sum, 0) * -1;

		const invisible = {
			name: '',
			type: 'bar',
			stack: 'A',
			tooltip: {
				show: false,
			},
			itemStyle: {
				borderColor: 'rgba(0,0,0,0)',
				color: 'rgba(0,0,0,0)',
			},
			emphasis: {
				itemStyle: {
					borderColor: 'rgba(0,0,0,0)',
					color: 'rgba(0,0,0,0)',
				},
			},
			data: [0, totalEquity, 0],
		};

		const asset = {
			name: 'Asset',
			stack: 'A',
			type: 'bar',
			itemStyle: {
				color: GobiColor.COLOR5,
			},
			data: [totalAsset, 0, 0],
		};

		const liability = {
			name: 'Liability',
			stack: 'A',
			type: 'bar',
			itemStyle: {
				color: GobiColor.COLOR6,
			},
			data: [0, totalLiability, 0],
		};

		const equity = {
			name: 'Equity',
			stack: 'A',
			type: 'bar',
			itemStyle: {
				color: GobiColor.COLOR7,
			},
			data: [0, 0, totalEquity],
		};

		this.series = [invisible, asset, liability, equity];

		this.saveHistory('xAxisData', 'series');
	}
}
