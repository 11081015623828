
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { DimensionMap, PermissionsGroup } from '@/store/models.def';

@Component({
	components: {
		AmountDisplayerFilterable,
	},
})
export default class TotalLiabilityNumberDisplayer extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateAsOf'];

	public amount: number = 0;
	public currentStockBalances: number = 0;

	public get expensiveHook() {
		const { selectedAsOfDate } = this;
		return JSON.stringify([selectedAsOfDate]);
	}

	public async expensiveCalc() {
		let pa = 0.4;
		let pb = 0;

		const ref = FilteredDatabase.ref('transactions').dateAsOf(
			this.selectedAsOfDate,
		);

		// Second task
		pb += pa;
		pa = 0.6;

		const accTypeList: string[] = ['CL', 'OL', 'LT'];
		const accTypeNameList: string[] = [
			'CURRENT LIABILITIES',
			'OTHER LIABILITIES',
			'NON-CURRENT LIABILITIES',
		];

		const accTypeMap: DimensionMap[] = accTypeList.map((code, index) => ({
			filterType: 'string',
			filterKey: 'accType',
			value: [code],
			text: accTypeNameList[index],
		}));

		const accTypeDatas = await this._loadDimension(
			ref,
			accTypeMap,
			pa,
			pb,
			'amount',
		);

		const result = accTypeDatas
			.map((dd) => dd.sum)
			.reduce((previous, current) => previous + current, 0);

		this.amount = result * -1;

		this.saveHistory('amount');
	}
}
