
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { DimensionMap, PermissionsGroup } from '@/store/models.def';
import moment from 'moment';

@Component({
	components: {
		AmountDisplayerFilterable,
	},
})
export default class TotalEquityNumberDisplayer extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateAsOf'];

	public amount: number = 0;

	public get expensiveHook() {
		const { selectedAsOfDate } = this;
		return JSON.stringify([selectedAsOfDate]);
	}

	public async expensiveCalc() {
		let pa = 0.4;
		let pb = 0;

		const ref = FilteredDatabase.ref('transactions').dateAsOf(
			this.selectedAsOfDate,
		);

		pb += pa;
		pa = 0.6;

		const accTypeList: string[] = [
			'RE',
			'EP',
			'SL',
			'SA',
			'OI',
			'EO',
			'CO',
			'TX',
			'CP',
			'RV',
			'AP',
		];
		const accTypeNameList: string[] = [
			'RETAINED EARNING',
			'EXPENSES',
			'SALES',
			'SALES ADJUSTMENT',
			'OTHER INCOME',
            'EXTRA ORDINARY INCOME',
			'CAPITAL',
			'COST OF GOODS SOLD',
			'TAXATION',
			// 'EQUITY',
			'RESERVE',
			'',
		];

		const accTypeMap: DimensionMap[] = accTypeList.map((code, index) => ({
			filterType: 'string',
			filterKey: 'accType',
			value: [code],
			text: accTypeNameList[index],
		}));

		const accTypeDatas = await this._loadDimension(
			ref,
			accTypeMap,
			pa,
			pb,
			'amount',
		);

        const systemRef = FilteredDatabase.ref('system');

        const system = await (await systemRef.get()).getCards();

        const startFinancialYear = system[0].val;

		const stockBalance = await FilteredDatabase.getStockMovement([
			moment(startFinancialYear).valueOf(),
			this.selectedAsOfDate,
		]);

		const equity =
            accTypeDatas.filter((dd) =>
                dd.value[0] === 'CP' ||
                dd.value[0] === 'RE' ||
                dd.value[0] === 'RV' ||
                dd.value[0] === 'AP')
			.map((dd) => dd.sum)
			.reduce((previous, current) => previous + current, 0);

        const netSales =
            accTypeDatas.filter((dd) =>
                dd.value[0] === 'SL' ||
                dd.value[0] === 'SA' ||
                dd.value[0] === 'OI' ||
                dd.value[0] === 'EO')
                .reduce((a, b) => a + b.sum, 0);

        const expenses =
            accTypeDatas.filter((dd) =>
                dd.value[0] === 'EP')
                .reduce((a, b) => a + b.sum, 0);

        const COGS =
            accTypeDatas.filter((dd) =>
                dd.value[0] === 'CO')
                .reduce((a, b) => a + b.sum, 0);

        const tax =
            accTypeDatas.filter((dd) =>
                dd.value[0] === 'TX')
                .reduce((a, b) => a + b.sum, 0);

        const grossProfit = Math.abs(netSales) - Math.abs(COGS - (stockBalance * -1));

        const netProfitBeforeTax = grossProfit - expenses;

		this.amount = Math.abs(equity) + netProfitBeforeTax - tax;

		this.saveHistory('amount');
	}
}
