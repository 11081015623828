
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { DimensionMap, PermissionsGroup } from '@/store/models.def';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';

@Component({
	components: {
		AmountDisplayerFilterable,
		SelectFilter,
	},
})
export default class CashInBankNumberDisplayer extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateAsOf'];

	public amount: number = 0;
	public currentStockBalances: number = 0;
	public selectedBankType: string | null = null;
	public bankTypeList: string[] = [];

	public get expensiveHook() {
		const { selectedAsOfDate, selectedBankType } = this;
		return JSON.stringify([selectedAsOfDate, selectedBankType]);
	}

	public async expensiveCalc() {
		const ref = FilteredDatabase.ref('transactions')
			.dateAsOf(this.selectedAsOfDate)
			.includes('accType', ['CA'])
			.includes('specialAccType', ['BA']);

		this.bankTypeList = [
			...(await (await ref.get()).getSet('specialAccTypeName')),
		];

		const ref2 = this.selectedBankType
			? ref.clone().includes('specialAccTypeName', [this.selectedBankType])
			: ref;

		const bankTypeList2 = [
			...(await (await ref2.get()).getSet('specialAccTypeName')),
		];

		const specialAccTypeMap: DimensionMap[] = bankTypeList2.map((code) => ({
			filterType: 'string',
			filterKey: 'specialAccTypeName',
			value: [code],
			text: code,
		}));

		const accTypeDatas = await this._loadDimension(
			this.selectedBankType ? ref2 : ref,
			specialAccTypeMap,
			1,
			0,
			'amount',
		);

		this.amount = accTypeDatas
			.map((dd) => dd.sum)
			.reduce((previous, current) => previous + current, 0);

		this.saveHistory('amount', 'bankTypeList');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
