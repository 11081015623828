
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import StackedBarChartFilterable from '@/components/reports-v2/components/filterables/StackedBarChartFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {
    DimensionMap,
    GroupNameSumPair,
    PermissionsGroup,
} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import { DataViewFormatter } from '@/components/reports-v2/components/elements/charts/helpers/dataViewFormatter';
import { TooltipFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tooltipFormatter';

@Component({
    components: {
        StackedBarChartFilterable,
    },
})
export default class FinancialPositionBarChart extends FilterWidget {
    public get permissionIds(): PermissionsGroup[] {
        return ['transactions'];
    }

    public filterIds: Array<
        | 'date'
        | 'dateAsOf'
        | 'dateRange'
        | 'stockItems'
        | 'agents'
        | 'customers'
        | 'suppliers'
    > = ['dateAsOf'];

    public legends: string[] = [];
    public xAxisData: string[] = [];
    public series: echarts.ECharts[] | any = [];

    public get currencySymbol() {
        return gdbx.currencySymbol;
    }

    public get dateFormatted() {
        return [this.selectedAsOfDate];
    }

    public get tooltipFormatter() {
        return TooltipFormatter.assetsLiabilities;
    }

    public get dataViewFormatter() {
        return DataViewFormatter.assestsLiabilities;
    }

    public get expensiveHook() {
        const { selectedAsOfDate } = this;
        return JSON.stringify([selectedAsOfDate]);
    }

    public async expensiveCalc() {
        const ref = FilteredDatabase.ref('transactions').dateAsOf(
            this.selectedAsOfDate,
        );

        const accTypeList: string[] = [
            'FA',
            'IV',
            'OA',
            'LT',
            'OL',
            'CP',
            'RV',
            'AP',
        ];
        const accTypeNameList: string[] = [
            'FIXED ASSETS',
            'INVESTMENT',
            'OTHER ASSETS',
            'NON-CURRENT LIABILITIES',
            'OTHER LIABILITIES',
            'EQUITY',
            'RESERVE',
            'APPROPRIATION ACCOUNT',
        ];

        const currentAssetSpecialAccTypeList: string[] = ['BA', 'CH', 'DP', 'DR'];
        const currentAssetSpecialAccTypeNameList: string[] = [
            'BANK',
            'CASH',
            '\'CREDITOR\'s DEPOSIT\'',
            'DEBTORS',
        ];

        const currentLiabilitySpecialAccTypeList: string[] = ['BA', 'DP', 'CR'];
        const currentLiabilitySpecialAccTypeNameList: string[] = [
            'BANK LIABILITIES',
            '\'DEBTOR\'s DEPOSIT\'',
            'CREDITORS',
        ];

        const retainedEarningSpecialAccTypeList: string[] = [
            'RE',
            'EP',
            'SL',
            'SA',
            'OI',
            'EO',
            'CO',
            'TX',
        ];
        const retainedEarningSpecialAccTypeNameList: string[] = [
            'RETAINED EARNING',
            'EXPENSES',
            'SALES',
            'SALES ADJUSTMENT',
            'OTHER INCOME',
            'Extra ',
            'COST OF GOODS SOLD',
            'TAXATION',
        ];

        const retainedEarningMap: DimensionMap[] =
            retainedEarningSpecialAccTypeList.map((code, index) => ({
                filterType: 'string',
                filterKey: 'accType',
                value: [code],
                text: retainedEarningSpecialAccTypeNameList[index],
            }));

        const currentAssetMap: DimensionMap[] = [
            {
                filterType: 'string',
                filterKey: 'accType',
                value: ['CA'],
                text: 'CURRENT ASSETS',
            },
        ];

        const currentLiabilityMap: DimensionMap[] = [
            {
                filterType: 'string',
                filterKey: 'accType',
                value: ['CL'],
                text: 'CURRENT LIABILITIES',
            },
        ];

        const accTypeMap: DimensionMap[] = accTypeList.map((code, index) => ({
            filterType: 'string',
            filterKey: 'accType',
            value: [code],
            text: accTypeNameList[index],
        }));

        const currentAssetSpecialAccTypeMap: DimensionMap[] =
            currentAssetSpecialAccTypeList.map((code, index) => ({
                filterType: 'string',
                filterKey: 'specialAccType',
                value: [code],
                text: currentAssetSpecialAccTypeNameList[index],
            }));

        const currentLiabilitySpecialAccTypeMap: DimensionMap[] =
            currentLiabilitySpecialAccTypeList.map((code, index) => ({
                filterType: 'string',
                filterKey: 'specialAccType',
                value: [code],
                text: currentLiabilitySpecialAccTypeNameList[index],
            }));

        let pa = 0.15;
        let pb = 0;

        const currentAssetsDD = await this._loadDimension(
            ref,
            currentAssetMap,
            pa,
            pb,
        );

        pb += pa;
        pa = 0.15;

        const groupedCurrentAssetDD = await this._loadDimension(
            ref,
            currentAssetsDD,
            pa,
            pb,
            (reff, paa, pbb) =>
                this._loadDimension(
                    reff,
                    currentAssetSpecialAccTypeMap,
                    paa,
                    pbb,
                    'amount',
                ),
        );

        pb += pa;
        pa = 0.15;

        const currentLiabilitiesDD = await this._loadDimension(
            ref,
            currentLiabilityMap,
            pa,
            pb,
        );

        pb += pa;
        pa = 0.15;

        const groupedCurrentLiabilitiesDD = await this._loadDimension(
            ref,
            currentLiabilitiesDD,
            pa,
            pb,
            (reff, paa, pbb) =>
                this._loadDimension(
                    reff,
                    currentLiabilitySpecialAccTypeMap,
                    paa,
                    pbb,
                    'amount',
                ),
        );

        const otherCurrentLiabilities =
            currentLiabilitiesDD[0].sum -
            groupedCurrentLiabilitiesDD
                .map((dd) => dd.sum)
                .reduce((a, b) => a + b, 0);

        const otherCurrentAssets =
            currentAssetsDD[0].sum -
            groupedCurrentAssetDD.map((dd) => dd.sum).reduce((a, b) => a + b, 0);

        pb += pa;
        pa = 0.15;

        const accTypeDatas = await this._loadDimension(
            ref,
            accTypeMap,
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.15;

        const retainedEarningDD = await this._loadDimension(
            ref,
            retainedEarningMap,
            pa,
            pb,
            'amount',
        );

        this.generateLoadingText(1);

        const currentAssetSubDD = groupedCurrentAssetDD[0].subDimension;
        const currentLiabilitySubDD = groupedCurrentLiabilitiesDD[0].subDimension;

        const currentAssetPair: GroupNameSumPair[] = currentAssetSubDD!.map(
            (dd) => ({
                groupName: dd.text,
                sum: dd.sum,
            }),
        );

        const otherCurrentAssetPair: GroupNameSumPair[] = [
            {
                groupName: 'OTHER CURRENT ASSETS',
                sum: otherCurrentAssets,
            },
        ];

        const currentLiabilityPair: GroupNameSumPair[] = currentLiabilitySubDD!.map(
            (dd) => ({
                groupName: dd.text,
                sum: dd.sum * -1,
            }),
        );

        const otherCurrentLiabilityPair: GroupNameSumPair[] = [
            {
                groupName: 'OTHER CURRENT LIABILITIES',
                sum: otherCurrentLiabilities * -1,
            },
        ];

        let ddPair: GroupNameSumPair[] = accTypeDatas.map((item) => ({
            groupName: item.text,
            sum:
                item.value[0] === 'LT' || item.value[0] === 'CP'
                    ? item.sum * -1
                    : item.sum,
        }));

        const stockBalance = await FilteredDatabase.getStockMovement([
            0,
            this.selectedAsOfDate,
        ]);

        const retainedEarningSum =
            stockBalance - retainedEarningDD.reduce((a, b) => a + b.sum, 0);
        ddPair.splice(1, 0, ...currentAssetPair);
        ddPair.splice(5, 0, ...otherCurrentAssetPair);
        ddPair.splice(6, 0, {
            groupName: 'STOCK BALANCE',
            sum: stockBalance,
        });
        ddPair.splice(10, 0, ...currentLiabilityPair);
        ddPair.splice(13, 0, ...otherCurrentLiabilityPair);
        ddPair.splice(16, 0, {
            groupName: 'RETAINED EARNING',
            sum: retainedEarningSum,
        });

        const equityIndex = ddPair.findIndex((o) => o.groupName === 'EQUITY');
        const filterNames = [
            'BANK LIABILITIES', 'CREDITORS', 'OTHER CURRENT LIABILITIES',
            'OTHER LIABILITIES', 'EQUITY', '\'DEBTOR\'s DEPOSIT\'', 'NON-CURRENT LIABILITIES', 'EQUITY',
        ];

        ddPair = ddPair.filter((pair) => filterNames.includes(pair.groupName));
        this.xAxisData = ddPair.map((pair) => pair.groupName);
        this.xAxisData.splice(equityIndex + 1, 3);

        this.series = ddPair.map((pair, i) => ({
            name: pair.groupName,
            type: 'bar',
            stack: 'assetLiabilities',
            data: ddPair.map((pair2) =>
                pair2.groupName === pair.groupName ? pair.sum : pair.sum < 0 ? 0 : 0,
            ),
        }));

        const retainedEarningIndex = equityIndex + 1;
        const reserveIndex = equityIndex + 2;
        const appropriationIndex = equityIndex + 3;

        this.series.forEach((o, i) => {
            if (i === appropriationIndex) {
                o.data!.splice(equityIndex, 3);
            } else if (i === reserveIndex) {
                o.data!.splice(appropriationIndex, 1);
                o.data!.splice(equityIndex, 2);
            } else if (i === retainedEarningIndex) {
                o.data!.splice(reserveIndex, 2);
                o.data!.splice(equityIndex, 1);
            } else {
                o.data!.splice(retainedEarningIndex, 3);
            }
        });

        const clone = ddPair.concat();
        clone.sort((a, b) => Math.abs(b.sum) - Math.abs(a.sum));
        this.legends = clone.slice(0, 6).map((o) => o.groupName);

        this.saveHistory('series', 'xAxisData', 'legends');
    }
}
