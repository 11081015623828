
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import LastSync from '@/components/LastSync.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import gdbx from '@/store/modules/gdbx';
// tslint:disable:max-line-length
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import QuickRatioBarChart from '@/components/reports-v2/components/codedWidgets/gl/QuickRatioBarChart.vue';
import AccountBalanceBarChart from '@/components/reports-v2/components/codedWidgets/gl/AccountBalanceBarChart.vue';
import TotalAssetNumberDisplayer from '@/components/reports-v2/components/codedWidgets/gl/TotalAssetNumberDisplayer.vue';
import TotalLiabilityNumberDisplayer from '@/components/reports-v2/components/codedWidgets/gl/TotalLiabilityNumberDisplayer.vue';
import TotalEquityNumberDisplayer from '@/components/reports-v2/components/codedWidgets/gl/TotalEquityNumberDisplayer.vue';
import FinancialPositionBarAssetChart from '@/components/reports-v2/components/codedWidgets/gl/FinancialPositionBarAssetChart.vue';
import FinancialPositionBarLiabilitiesEquitiesChart from '@/components/reports-v2/components/codedWidgets/gl/FinancialPositionBarLiabilitiesEquitiesChart.vue';
import CashOnHandNumberDisplayer from '@/components/reports-v2/components/codedWidgets/gl/CashOnHandNumberDisplayer.vue';
import CashInBankNumberDisplayer from '@/components/reports-v2/components/codedWidgets/gl/CashInBankNumberDisplayer.vue';

@Component({
	components: {
		StickyFilterContainer,
		DateFilter,
		LastSync,
		SelectFilter,
		TotalAssetNumberDisplayer,
		TotalLiabilityNumberDisplayer,
		TotalEquityNumberDisplayer,
		QuickRatioBarChart,
		AccountBalanceBarChart,
		FinancialPositionBarAssetChart,
		FinancialPositionBarLiabilitiesEquitiesChart,
		CashOnHandNumberDisplayer,
		CashInBankNumberDisplayer,
	},
})
export default class BalanceSheet extends Vue {
	public selectedDay: number = moment().endOf('day').valueOf();
	public selectedDateRange: [number, number] = [
		moment().startOf('year').valueOf(),
		moment().endOf('day').valueOf(),
	];

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public get selectedAsOfDate() {
		return moment(this.selectedDay).endOf('day').valueOf();
	}

	public get selectedAgents() {
		return gdbx.allAgents;
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
